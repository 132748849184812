import { createGlobalStyle } from "styled-components";

const globaProps = {
    fonts: {
        regular: ["TTFirsNeueRegular", "Arial", "sans-serif"].join(","),
        medium: ["TTFirsNeueMedium", "Arial", "sans-serif"].join(","),
    },
};

export const darkTheme = {
    ...globaProps,

    colors: {
        primary1: "#182C47",
        primary2: "#122135",
        primary3: "#0D1623",
        secondary: "#FFFFFF",
        blue: "#0D66FF",
        blueAlt: "#3781FF",
        green: "#2DC991",
        yellow: "#FFEA7D",
        red: "#FF4567",
        inactive: "#666666",
        white: "#FFFFFF",
        blackText: "#0D1623",
        borderOnWhite: "rgba(13, 22, 34, 0.1)",
        grayscaleDark: "#000817",
    },
};

export const lightTheme = {
    ...globaProps,

    colors: {
        primary1: "#EAEAEA",
        primary2: "#F3F3F3",
        primary3: "#FFFFFF",
        secondary: "#0D1623",
        blue: "#0D66FF",
        blueAlt: "#3781FF",
        green: "#00C27C",
        yellow: "#FF9900",
        red: "#FF2F54",
        inactive: "#999999",
        white: "#FFFFFF",
        blackText: "#0D1623",
        borderOnWhite: "rgba(13, 22, 34, 0.1)",
        grayscaleDark: "#000817",
    },
};

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.primary2};
    color: ${({ theme }) => theme.colors.secondary};
    font-family: ${({ theme }) => theme.fonts.regular};
  }
`;
