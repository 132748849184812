import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, darkTheme } from "./theme";
import { withSnackbar } from "notistack";
import LicensePage from "./loginpage/components/LicensePage";

class App extends React.Component {
    state = {};

    render() {
        return (
            <ThemeProvider theme={darkTheme}>
                <GlobalStyles />
                <LicensePage />
            </ThemeProvider>
        );
    }
}
export default withSnackbar(App);
