import React from "react";

export const AuthLogo = () => (
    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path
                d="M64.0485 85.2832C62.9426 85.3126 61.8426 85.1123 60.8173 84.6949C59.7921 84.2775 58.8638 83.652 58.0906 82.8575C57.3174 82.0631 56.7159 81.1168 56.3239 80.0781C55.9319 79.0393 55.7577 77.9305 55.8124 76.821C55.7715 75.7085 55.9543 74.5991 56.35 73.5592C56.7458 72.5192 57.3462 71.5701 58.1156 70.7685C58.885 69.9669 59.8074 69.3292 60.8277 68.8937C61.8481 68.4582 62.9454 68.2337 64.0541 68.2337C65.1629 68.2337 66.2602 68.4582 67.2805 68.8937C68.3009 69.3292 69.2233 69.9669 69.9927 70.7685C70.762 71.5701 71.3625 72.5192 71.7583 73.5592C72.154 74.5991 72.3368 75.7085 72.2959 76.821C72.3506 77.9314 72.1762 79.0413 71.7835 80.0808C71.3908 81.1204 70.7883 82.0672 70.0139 82.8618C69.2394 83.6564 68.3097 84.2817 67.2832 84.6984C66.2566 85.1151 65.1554 85.3142 64.0485 85.2832V85.2832ZM64.0485 63.2C55.9927 63.2 49.9424 69.2751 49.9424 76.7757C49.9424 84.2763 55.9927 90.3515 64.0485 90.3515C72.1043 90.3515 78.1434 84.3555 78.1434 76.821C78.1434 69.2864 72.0931 63.2452 64.026 63.2452"
                fill="white"
            />
            <path
                d="M92.5195 70.3725C90.878 70.3486 89.2812 70.9098 88.0128 71.9563C87.4664 72.3801 87.0072 72.9065 86.6607 73.5062H86.4692L86.4692 70.9495L81.3428 70.9495L81.3428 89.6953L86.4692 89.6953L86.4692 79.7624C86.4234 79.1436 86.5019 78.5219 86.7002 77.9342C86.8985 77.3466 87.2125 76.8049 87.6235 76.3416C88.0345 75.8782 88.5341 75.5026 89.0926 75.2371C89.6511 74.9716 90.2571 74.8215 90.8746 74.7959C93.2519 74.7959 94.7166 76.2327 94.7166 79.027L94.7166 89.6953L99.843 89.6953L99.843 78.2917C99.843 73.099 97.0939 70.3725 92.5195 70.3725"
                fill="white"
            />
            <path
                d="M107.178 78.2917C107.452 77.2856 108.049 76.3982 108.876 75.7664C109.703 75.1347 110.713 74.7936 111.752 74.7959C112.777 74.7709 113.779 75.1067 114.584 75.7451C115.388 76.3836 115.945 77.2844 116.158 78.2917L107.178 78.2917ZM121.645 80.3054C121.679 78.9917 121.446 77.6847 120.961 76.4642C120.476 75.2436 119.749 74.1349 118.823 73.2056C117.898 72.2763 116.793 71.5459 115.578 71.0589C114.362 70.5718 113.061 70.3383 111.752 70.3725C110.424 70.3275 109.101 70.5513 107.861 71.0305C106.621 71.5097 105.49 72.2345 104.534 73.1619C103.579 74.0892 102.82 75.2 102.301 76.4283C101.782 77.6565 101.515 78.977 101.515 80.3111C101.515 81.6452 101.782 82.9657 102.301 84.1939C102.82 85.4222 103.579 86.533 104.534 87.4603C105.49 88.3877 106.621 89.1125 107.861 89.5917C109.101 90.0709 110.424 90.2947 111.752 90.2497C113.878 90.4042 115.994 89.8336 117.756 88.6304C119.519 87.4272 120.825 85.6619 121.464 83.6202L115.966 83.6202C115.742 84.0467 115.445 84.4298 115.087 84.7515C114.137 85.5324 112.934 85.935 111.707 85.8828C110.528 85.8151 109.409 85.3379 108.542 84.533C107.674 83.7282 107.113 82.6456 106.953 81.4707L121.599 81.4707L121.645 80.3054Z"
                fill="white"
            />
            <path d="M25.0539 65.5531L19.5557 65.5531L19.5557 89.6954L25.0539 89.6954L25.0539 65.5531Z" fill="white" />
            <path
                d="M39.7123 70.3725C38.0704 70.3467 36.473 70.9081 35.2055 71.9564C34.6587 72.383 34.1963 72.9087 33.8422 73.5063H33.662L33.662 70.9495L28.5693 70.9495L28.5693 89.6954L33.6958 89.6954L33.6958 79.7624C33.6499 79.1446 33.7281 78.5237 33.9257 77.9368C34.1233 77.3498 34.4363 76.8087 34.8461 76.3455C35.2559 75.8823 35.7542 75.5065 36.3114 75.2403C36.8686 74.9741 37.4734 74.823 38.0898 74.796C40.4784 74.796 41.9431 76.2327 41.9431 79.0271L41.9431 89.6954L47.0695 89.6954L47.0695 78.2917C47.0695 73.099 44.3204 70.3725 39.7461 70.3725"
                fill="white"
            />
            <path
                d="M128.777 45.3139C122.467 35.826 113.67 28.2733 103.355 23.4896C93.04 18.7059 81.6093 16.8773 70.3246 18.2057C59.04 19.5342 48.3405 23.9679 39.4073 31.0175C30.4741 38.0671 23.6547 47.4584 19.7021 58.1543L26.1581 58.1543C30.015 48.8783 36.293 40.819 44.3304 34.8263C52.3677 28.8335 61.8664 25.1293 71.8252 24.1042C81.7839 23.079 91.8336 24.771 100.915 29.0016C109.996 33.2323 117.772 39.8448 123.423 48.1421C129.074 56.4395 132.391 66.1141 133.024 76.146C133.656 86.1779 131.582 96.1954 127.018 105.142C122.455 114.089 115.572 121.634 107.095 126.981C98.618 132.329 88.8612 135.28 78.8532 135.525L78.8532 141.555C89.8963 141.313 100.672 138.095 110.052 132.24C119.433 126.384 127.074 118.104 132.177 108.268C137.28 98.4311 139.656 87.3983 139.058 76.3235C138.46 65.2488 134.909 54.5388 128.777 45.3139"
                fill="#0D66FF"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="120.556" height="124.444" fill="white" transform="translate(19.5557 17.7778)" />
            </clipPath>
        </defs>
    </svg>
);

export const AuthFooterLogo = () => (
    <svg width="130" height="20" viewBox="0 0 130 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.44 14.3C7.52 14.3 9.12 12.6533 9.12 10.3C9.12 7.94666 7.52 6.29999 5.44 6.29999C3.36 6.29999 1.75333 7.94666 1.75333 10.3C1.75333 12.6533 3.35334 14.3 5.44 14.3ZM0 0.533325H1.73333V6.60666L1.95333 6.27999C2.39801 5.73702 2.96542 5.30757 3.60874 5.02705C4.25207 4.74654 4.95287 4.62301 5.65334 4.66666C8.49334 4.66666 10.86 7.05332 10.86 10.3067C10.86 13.56 8.49334 15.94 5.65334 15.94C4.95567 15.9783 4.25907 15.8474 3.62294 15.5584C2.98681 15.2693 2.43 14.8307 2 14.28L1.78 13.98V15.7133H0V0.533325Z"
            fill="white"
        />
        <path
            d="M13.4601 4.87332L15.8468 14.0867H17.4735L20.2868 4.87332H22.1335L17.5801 19.8333H15.7335L17.0401 15.7133H14.5401L11.6201 4.87332H13.4601Z"
            fill="white"
        />
        <path
            d="M28.8535 0.533325H30.8069V7.14666H38.9335V0.533325H40.8869V15.7133H38.9335V8.99333H30.8069V15.7133H28.8535V0.533325Z"
            fill="white"
        />
        <path
            d="M52.1597 9.33332C52.0259 8.46274 51.5759 7.67202 50.8958 7.1124C50.2156 6.55277 49.353 6.26353 48.473 6.29998C47.5877 6.26885 46.721 6.55868 46.0325 7.11609C45.344 7.67349 44.8801 8.46093 44.7263 9.33332H52.1597ZM48.473 4.66665C49.2036 4.65117 49.9297 4.78376 50.6077 5.05644C51.2856 5.32912 51.9014 5.73625 52.4178 6.25327C52.9342 6.7703 53.3406 7.38653 53.6124 8.06482C53.8843 8.74311 54.016 9.46942 53.9997 10.2V10.8667H44.6663C44.7498 11.8145 45.1858 12.6965 45.8882 13.3384C46.5905 13.9803 47.5082 14.3353 48.4597 14.3333C49.4741 14.3556 50.4605 13.9991 51.2263 13.3333C51.5522 13.0552 51.8313 12.7266 52.053 12.36H53.7863C53.3826 13.4385 52.6509 14.3633 51.6942 15.0042C50.7375 15.6452 49.604 15.9702 48.453 15.9333C47.7148 15.9456 46.9818 15.8074 46.2987 15.5272C45.6156 15.2471 44.9967 14.8308 44.4796 14.3038C43.9625 13.7767 43.5582 13.15 43.2911 12.4616C43.0241 11.7733 42.9 11.0378 42.9263 10.3C42.9008 9.56067 43.0262 8.82391 43.2948 8.13465C43.5634 7.44538 43.9696 6.81806 44.4886 6.29093C45.0076 5.76381 45.6286 5.34794 46.3136 5.06868C46.9986 4.78942 47.7334 4.65263 48.473 4.66665Z"
            fill="white"
        />
        <path
            d="M60.6124 14.3C62.6991 14.3 64.2991 12.6533 64.2991 10.3C64.2991 7.94667 62.6991 6.3 60.6124 6.3C58.5257 6.3 56.9324 7.94667 56.9324 10.3C56.9324 12.6533 58.5324 14.3 60.6124 14.3ZM60.3991 4.66667C61.0989 4.63864 61.7957 4.77268 62.4352 5.05831C63.0747 5.34394 63.6396 5.77345 64.0857 6.31333L64.2791 6.64V4.87333H66.0124V15.7133H64.2791V14L64.0857 14.3C63.6451 14.8463 63.0813 15.2804 62.4404 15.5667C61.7996 15.8529 61.1 15.9831 60.3991 15.9467C57.5591 15.9467 55.1924 13.5667 55.1924 10.3133C55.1924 7.06 57.5591 4.66667 60.3991 4.66667Z"
            fill="white"
        />
        <path
            d="M73.3332 14.3C75.4132 14.3 77.0199 12.6533 77.0199 10.3C77.0199 7.94666 75.4132 6.29999 73.3332 6.29999C71.2532 6.29999 69.6465 7.94666 69.6465 10.3C69.6465 12.6533 71.2532 14.3 73.3332 14.3ZM73.0799 4.66666C73.778 4.63674 74.4736 4.76789 75.1129 5.04997C75.7522 5.33205 76.318 5.75748 76.7665 6.29332L76.9665 6.61999V0.533325H78.6999V15.7133H76.9665V14L76.7665 14.3C76.327 14.8476 75.7633 15.2825 75.1222 15.5689C74.4811 15.8552 73.781 15.9847 73.0799 15.9467C70.2399 15.9467 67.8799 13.5667 67.8799 10.3133C67.8799 7.05999 70.2399 4.67332 73.0799 4.67332"
            fill="white"
        />
        <path
            d="M87.8268 8.66666C90.2334 8.66666 91.5135 7.33332 91.5135 5.51999C91.5135 3.70666 90.2334 2.37999 87.8268 2.37999H83.3801V8.66666H87.8268ZM81.4268 0.533325H87.8268C91.2935 0.533325 93.4601 2.67999 93.4601 5.51999C93.4601 8.35999 91.2935 10.5067 87.8268 10.5067H83.3801V15.7133H81.4268V0.533325Z"
            fill="white"
        />
        <path
            d="M99.8592 14.3C100.384 14.3135 100.905 14.2186 101.391 14.021C101.877 13.8235 102.317 13.5276 102.683 13.152C103.049 12.7764 103.334 12.3292 103.519 11.8384C103.704 11.3476 103.786 10.8239 103.759 10.3C103.781 9.77382 103.696 9.24869 103.51 8.75615C103.323 8.26362 103.039 7.81384 102.675 7.43386C102.31 7.05387 101.873 6.75152 101.388 6.54498C100.904 6.33844 100.383 6.23197 99.8559 6.23197C99.3293 6.23197 98.8081 6.33844 98.3237 6.54498C97.8393 6.75152 97.4016 7.05387 97.037 7.43386C96.6724 7.81384 96.3884 8.26362 96.202 8.75615C96.0157 9.24869 95.9308 9.77382 95.9526 10.3C95.9267 10.8242 96.0093 11.3481 96.1953 11.8389C96.3812 12.3297 96.6665 12.7769 97.0332 13.1523C97.3999 13.5278 97.8402 13.8235 98.3265 14.021C98.8127 14.2185 99.3346 14.3135 99.8592 14.3ZM99.8592 4.65332C100.975 4.65332 102.065 4.9841 102.993 5.60383C103.92 6.22356 104.643 7.10441 105.07 8.13499C105.497 9.16556 105.608 10.2996 105.391 11.3936C105.173 12.4877 104.636 13.4926 103.847 14.2814C103.059 15.0702 102.054 15.6073 100.96 15.8249C99.8655 16.0426 98.7315 15.9309 97.7009 15.504C96.6703 15.0771 95.7895 14.3542 95.1697 13.4267C94.55 12.4992 94.2192 11.4088 94.2192 10.2933C94.197 9.54725 94.3278 8.80454 94.6035 8.11094C94.8793 7.41735 95.2941 6.78756 95.8225 6.26041C96.3509 5.73325 96.9817 5.31988 97.6759 5.04578C98.3702 4.77168 99.1132 4.64266 99.8592 4.66665"
            fill="white"
        />
        <path
            d="M107.333 4.87333H109.066V15.7133H107.333V4.87333ZM108.199 0.426657C108.435 0.426657 108.666 0.496645 108.862 0.62777C109.059 0.758895 109.212 0.945267 109.302 1.16332C109.392 1.38137 109.416 1.62132 109.37 1.8528C109.324 2.08428 109.21 2.29691 109.043 2.4638C108.876 2.6307 108.664 2.74435 108.432 2.79039C108.201 2.83644 107.961 2.81281 107.743 2.72249C107.525 2.63217 107.338 2.47921 107.207 2.28297C107.076 2.08673 107.006 1.85601 107.006 1.61999C107.003 1.46253 107.032 1.30614 107.091 1.16014C107.15 1.01413 107.238 0.8815 107.35 0.770145C107.461 0.658789 107.594 0.570991 107.74 0.511979C107.886 0.452967 108.042 0.423949 108.199 0.426657Z"
            fill="white"
        />
        <path
            d="M111.566 4.87332H113.333V6.49998C113.557 6.14647 113.844 5.83694 114.18 5.58665C114.988 4.96813 115.982 4.63941 117 4.65332C119.6 4.65332 121.333 6.38665 121.333 9.31998V15.72H119.6V9.42665C119.6 7.25998 118.413 6.27998 116.673 6.27998C114.673 6.27998 113.34 7.69332 113.34 9.85998V15.7133H111.606L111.566 4.87332Z"
            fill="white"
        />
        <path
            d="M122.733 4.87333H124.14V2.48666H125.873V4.87333H129.347V6.5H125.873V14.0867H129.453V15.7133H124.14V6.5H122.733V4.87333Z"
            fill="white"
        />
    </svg>
);
