import React, { Component } from "react";
import { AuthLogo, AuthFooterLogo } from "../logo";
import { Button, Text } from "headpoint-react-components";
import LoginAboutModal from "../about/LoginAboutModal";
import {
    LoginLayout,
    LoginLayoutContainer,
    LoginLayoutLogoWrapper,
    LoginForm,
    LoginFormItem,
    LoginLayoutFooter,
    LoginLayoutFooterLinks,
    LoginLayoutFooterLogoWrapper,
} from "../../layout/LoginLayout";

import { withSnackbar } from "notistack";
import { IsLicenseFrontend } from "../../services/license";
import { LoaderAnimation } from "../../components/LoaderAnimation/LoaderAnimation";

class LicensePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: "",
            password: "",
            showModal: false,
            isLoading: false,
        };
    }

    componentDidMount() {
        if (window.location.pathname !== "/") {
            window.location.href = "/";
        }

        this.startLicenseChecking();
    }

    startLicenseChecking = async () => {
        setInterval(async () => {
            const isIframe = () => window.frameElement;

            if (isIframe()) {
                return;
            }

            if (await IsLicenseFrontend()) {
                return;
            }

            window.location.href = "/";
        }, 1000);
    };

    handleSubmit = async () => {
        const input = document.getElementById("fileInput");
        if (!input) {
            document.createElement("fileInput");
        }

        input.onclick = function () {
            this.value = null;
        };

        await input.click();
    };

    upload = async (file) => {
        if (!file) {
            return;
        }

        let formData = new FormData();
        formData.append(file.name, file);

        await fetch("/license/upload", {
            method: "POST",
            body: formData,
        })
            .then((response) => this.handleResponse(response.status))
            .catch((error) => console.error(error));
    };

    handleResponse(statusCode) {
        switch (statusCode) {
            case 200:
                this.props.enqueueSnackbar("Лицензия успешно загружена", { variant: "success" });
                this.setState({ isLoading: true });
                break;
            case 403:
                this.props.enqueueSnackbar("Невалидный файл лицензии", { variant: "error" });
                this.props.enqueueSnackbar("Невалидный файл лицензии", { variant: "error" });
                break;
            default:
                this.props.enqueueSnackbar("Не удалось загрзуить файл лицензии", { variant: "error" });
                break;
        }
    }

    CloseAbout = () => this.setState({ showModal: false });
    ShowAbout = () => this.setState({ showModal: true });

    render() {
        const { isLoading } = this.state;
        return (
            <>
                {isLoading && <LoaderAnimation message={"Применение лицензии..."} />}
                <div className="wrappper">
                    <LoginLayout>
                        <LoginLayoutContainer>
                            <LoginLayoutLogoWrapper>
                                <AuthLogo />
                            </LoginLayoutLogoWrapper>

                            <LoginForm>
                                <LoginFormItem style={{ textAlign: "center" }}>
                                    <Text>Лицензионный ключ не найден</Text>
                                </LoginFormItem>

                                <LoginFormItem>
                                    <input
                                        id={"fileInput"}
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={async () => {
                                            const input = document.getElementById("fileInput");
                                            await this.upload(input.files[0]);
                                        }}
                                    />
                                    <Button
                                        label="Загрузить файл лицензии"
                                        onClick={this.handleSubmit}
                                        type="button"
                                        variant="secondary"
                                        icon={"upload"}
                                        fullWidth
                                    />
                                </LoginFormItem>
                            </LoginForm>
                        </LoginLayoutContainer>

                        <LoginLayoutFooter>
                            <LoginLayoutFooterLinks>
                                <Button variant="ghost" label="О платформе" noPadding onClick={this.ShowAbout} />
                            </LoginLayoutFooterLinks>
                            <LoginLayoutFooterLogoWrapper>
                                <AuthFooterLogo />
                            </LoginLayoutFooterLogoWrapper>
                        </LoginLayoutFooter>
                    </LoginLayout>

                    {this.state.showModal ? <LoginAboutModal Close={this.CloseAbout} /> : null}
                </div>
            </>
        );
    }
}

export default withSnackbar(LicensePage);
