const path = "/general-api/unknown/url";

export const IsLicenseFrontend = async () => {
    try {
        let response = await fetch(`${path}`, {
            method: "GET",
        });

        return response.status !== 404;
    } catch (ex) {
        console.error(ex);
        return false;
    }
};
