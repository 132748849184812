import React from "react";
import styled from "styled-components";
import { ModalBody, ModalBodyItem, Text, Space } from "headpoint-react-components";
import { ModalOverall } from "../ModalOverall";

const ModalButtonsContainer = styled.div`
    margin: 25px 30px 0px 20px;
    float: center;
    display: flex;
    align-items: center;
`;

export class LoaderAnimation extends React.Component {
    render() {
        return (
            <ModalOverall size="sm">
                <ModalBody>
                    <ModalBodyItem>
                        <ModalButtonsContainer>
                            <>
                                <img
                                    src="/img/loader.gif"
                                    alt="img"
                                    className="loader-animation"
                                    style={{
                                        width: "48px",
                                        height: "38px",
                                        margin: "5px",
                                    }}
                                />
                                <Space />
                                <Text>{this.props.message}</Text>
                            </>
                        </ModalButtonsContainer>
                    </ModalBodyItem>
                </ModalBody>
            </ModalOverall>
        );
    }
}
