import React from "react";
import styled from "styled-components";
import { rgba } from "polished";

const LoginLayoutWrapper = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
`;

const LoginLayoutBgImg = () => (
    <LoginLayoutBgImgSvg width="246" height="291" viewBox="0 0 246 291" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clipLoginImg)">
            <path
                d="M272.086 291L272.086 274.64C255.815 274.64 241.41 273.031 228.873 270.081C217.936 267.399 208.066 263.913 198.996 259.622C183.258 252.112 169.654 242.457 156.583 231.73C146.713 223.684 137.11 215.37 126.973 207.056C119.504 200.888 111.502 194.719 102.966 189.087C90.1616 180.773 75.757 173.264 58.9516 167.9C42.1463 162.536 22.9401 159.586 -0.000537828 159.586L-0.000539258 175.946C16.5381 175.946 30.6759 177.555 43.2133 180.773C54.1501 183.455 64.0199 186.942 73.0895 191.233C88.8279 198.742 102.432 208.397 115.503 219.125C125.373 227.17 134.976 235.484 145.113 243.798C152.582 249.967 160.584 256.135 169.12 261.767C181.924 270.081 196.329 277.59 213.134 282.954C230.206 288.05 249.413 291 272.086 291Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M272.086 142.247L272.086 125.887C255.815 125.887 241.41 124.278 228.873 121.328C217.936 118.646 208.066 115.159 198.996 110.868C183.258 103.359 169.654 93.7041 156.583 82.9765C146.713 74.9307 137.11 66.6168 126.973 58.3028C119.504 52.1344 111.502 45.966 102.966 40.334C90.1616 32.0201 75.757 24.5107 58.9517 19.1469C42.1463 13.783 22.9402 10.8329 -0.00050731 10.8329L-0.00050874 27.1926C16.5381 27.1926 30.6759 28.8018 43.2133 32.0201C54.1501 34.702 64.02 38.1885 73.0895 42.4795C88.8279 49.9889 102.432 59.6438 115.503 70.3714C125.373 78.4172 134.976 86.7311 145.113 95.0451C152.582 101.213 160.584 107.382 169.12 113.014C181.924 121.328 196.329 128.837 213.134 134.201C230.206 139.297 249.413 142.247 272.086 142.247Z"
                fill="url(#paint1_linear)"
            />
            <path
                d="M273 216.167L273 199.841C256.461 199.841 242.324 198.235 229.786 195.023C218.849 192.347 208.98 188.867 199.91 184.585C184.172 177.091 170.567 167.456 157.496 156.75C147.627 148.72 138.024 140.423 127.887 132.126C120.418 125.971 112.415 119.815 103.879 114.194C91.0752 105.897 76.6706 98.4031 59.8652 93.0502C43.0598 87.6973 23.8537 84.7532 0.913006 84.7532L0.913004 101.08C17.4516 101.08 31.5895 102.685 44.1268 105.897C55.0637 108.574 64.9335 112.053 74.003 116.335C89.7414 123.829 103.346 133.465 116.417 144.17C126.286 152.2 135.89 160.497 146.026 168.794C153.495 174.95 161.498 181.106 170.034 186.726C182.838 195.023 197.242 202.517 214.048 207.87C230.853 212.955 250.326 216.167 273 216.167Z"
                fill="url(#paint2_linear)"
            />
            <path
                d="M273 67.4139L273 51.0875C256.461 51.0875 242.324 49.4817 229.786 46.2699C218.849 43.5935 208.98 40.1141 199.91 35.8318C184.172 28.3377 170.567 18.7025 157.496 7.99664C147.627 -0.0327182 138.024 -8.32973 127.887 -16.6267C120.418 -22.7826 112.415 -28.9384 103.879 -34.559C91.0752 -42.856 76.6706 -50.3501 59.8652 -55.703C43.0599 -61.0559 23.8537 -64 0.913067 -64L0.913065 -47.6736C17.4517 -47.6736 31.5895 -46.0677 44.1269 -42.856C55.0637 -40.1795 64.9335 -36.7001 74.0031 -32.4178C89.7414 -24.9237 103.346 -15.2885 116.417 -4.58269C126.286 3.44667 135.89 11.7437 146.026 20.0407C153.495 26.1965 161.498 32.3524 170.034 37.9729C182.838 46.2699 197.242 53.764 214.048 59.1169C230.853 64.2022 250.326 67.4139 273 67.4139Z"
                fill="url(#paint3_linear)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear"
                x1="272.62"
                y1="231.583"
                x2="0.533481"
                y2="231.583"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0D66FF" />
                <stop offset="1" stopColor="#0D66FF" stopOpacity="0" />
            </linearGradient>
            <linearGradient
                id="paint1_linear"
                x1="272.62"
                y1="82.8295"
                x2="0.533512"
                y2="82.8295"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0D66FF" />
                <stop offset="1" stopColor="#0D66FF" stopOpacity="0" />
            </linearGradient>
            <linearGradient
                id="paint2_linear"
                x1="273.534"
                y1="156.75"
                x2="1.44703"
                y2="156.75"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0D66FF" stopOpacity="0" />
                <stop offset="1" stopColor="#0D66FF" />
            </linearGradient>
            <linearGradient
                id="paint3_linear"
                x1="273.534"
                y1="7.99662"
                x2="1.44709"
                y2="7.9966"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0D66FF" stopOpacity="0" />
                <stop offset="1" stopColor="#0D66FF" />
            </linearGradient>
            <clipPath id="clipLoginImg">
                <rect width="273" height="355" fill="white" transform="translate(273 291) rotate(-180)" />
            </clipPath>
        </defs>
    </LoginLayoutBgImgSvg>
);

const LoginLayoutBgImgSvg = styled.svg`
    position: absolute;
    top: -64px;
    right: -26px;
    z-index: 1;
    width: 273px;
    height: 355px;
`;

export const LoginLayoutHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    padding: 40px;
`;

export const LoginLayoutContainer = styled.div`
    width: 320px;
    margin: 64px auto auto auto;
`;

export const LoginLayoutLogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 auto 40px auto;
`;

export const LoginForm = styled.form`
    & input,
    & button {
        height: 40px;
    }
`;

export const LoginFormItem = styled.div`
    & + & {
        margin-top: 24px;
    }
`;

export const LoginLayoutDividerText = styled.p`
    margin-top: 24px;
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: ${({ theme }) => rgba(theme.colors.white, 0.5)};
`;

export const LoginLayoutSecondaryMethods = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin: 24px -8px 0 -8px;

    & button,
    & a {
        flex: 1;
        height: 40px;
        margin: 0 8px;
    }
`;

export const LoginLayoutFooter = styled.footer`
    display: flex;
    align-items: flex-end;
    margin: auto 0 0 0;
    padding: 40px;
`;

export const LoginLayoutFooterLinks = styled.div`
    display: flex;
    width: 33.33%;
`;

export const LoginLayoutFooterLogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 33.33%;
`;

export const LoginLayout = ({ children }) => (
    <LoginLayoutWrapper>
        <LoginLayoutBgImg />
        {children}
    </LoginLayoutWrapper>
);
