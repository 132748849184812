import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import PropTypes from "prop-types";

const ModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    background-color: ${({ theme }) => rgba(theme.colors.primary3, 0.75)};
`;

const ModalWrapper = styled.div`
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    overflow: auto;
`;

const getModalSize = (size) => {
    switch (size) {
        case "sm":
            return "480px";

        case "md":
            return "736px";

        case "lg":
            return "960px";

        case "xlg":
            return "calc(100% - 256px)";

        default:
            return "480px";
    }
};

const ModalContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.primary1};
    width: ${({ size }) => getModalSize(size)};
    display: flex;
    flex-direction: column;
    margin: 40px auto;

    ${({ size }) =>
        size === "xlg" &&
        css`
            min-width: 960px;
        `}
`;

export const ModalOverall = ({ children, size, closeHandler }) => {
    const modalRef = useRef(null);

    const handleBodyClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) closeHandler();
    };

    return (
        <div>
            <ModalBackdrop />

            <ModalWrapper onClick={handleBodyClick}>
                <ModalContainer size={size} ref={modalRef}>
                    {children}
                </ModalContainer>
            </ModalWrapper>
        </div>
    );
};

ModalOverall.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    size: PropTypes.oneOf(["sm", "md", "lg", "xlg"]),
    closeHandler: PropTypes.func,
};

ModalOverall.defaultProps = {
    children: null,
    size: "md",
    closeHandler: () => {},
};

const ModalHeaderWrapper = styled.div`
    position: relative;
    min-height: 73px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary3};
    padding: ${({ align }) => (align === "center" ? "24px 80px" : "24px 80px 24px 48px")};
`;

const ModalHeaderTitle = styled.div`
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 20px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.secondary};
    text-align: ${({ align }) => (align === "center" ? "center" : "left")};
`;

const ModalHeaderCloseBtn = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: transparent;
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
    border: 1px solid ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
    cursor: pointer;
    font-size: 16px;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
    transition: color 0.16s linear, border-color 0.16s linear;

    &:before {
        content: "\\e913";

        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.colors.secondary};
            border-color: ${({ theme }) => rgba(theme.colors.secondary, 0.5)};
        }

        &:focus,
        &:active {
            color: ${({ theme }) => rgba(theme.colors.secondary, 0.75)};
            border-color: ${({ theme }) => rgba(theme.colors.secondary, 0.25)};
            outline: none;
        }
    }
`;

const ModalHeaderActions = styled.div`
    position: absolute;
    top: 20px;
    right: 24px;
    z-index: 1;
    display: flex;
    align-items: center;
`;

export const ModalHeader = ({ title, align, closeHandler, actions }) => (
    <ModalHeaderWrapper align={align}>
        {title && <ModalHeaderTitle align={align}>{title}</ModalHeaderTitle>}

        <ModalHeaderActions>
            {actions}
            <ModalHeaderCloseBtn type="button" onClick={closeHandler} />
        </ModalHeaderActions>
    </ModalHeaderWrapper>
);

ModalHeader.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    align: PropTypes.oneOf(["left", "center"]),
    closeHandler: PropTypes.func,
    actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ModalHeader.defaultProps = {
    title: "",
    align: "left",
    closeHandler: () => {},
    actions: null,
};

const ModalFooterWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    min-height: 96px;
    border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    padding: 32px 48px;
`;

export const ModalFooter = ({ children }) => <ModalFooterWrapper>{children}</ModalFooterWrapper>;

ModalFooter.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ModalFooter.defaultProps = {
    children: null,
};

const ModalFooterItemStyled = styled.div`
    & + & {
        margin-left: 16px;
    }
`;

export const ModalFooterItem = ({ children }) => <ModalFooterItemStyled>{children}</ModalFooterItemStyled>;

ModalFooterItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ModalFooterItem.defaultProps = {
    children: null,
};

const ModalBodyStyled = styled.div`
    display: flex;
    flex-wrap: wrap;

    & + & {
        border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    }
`;

export const ModalBody = ({ children }) => <ModalBodyStyled>{children}</ModalBodyStyled>;

ModalBody.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ModalBody.defaultProps = {
    children: null,
};

const ModalBodyItemStyled = styled.div`
    flex: 1;
    padding: 24px 48px 48px 48px;
    max-width: 100%;

    & + & {
        border-left: 1px solid ${({ theme }) => theme.colors.primary3};
    }
`;

export const ModalBodyItem = ({ children }) => <ModalBodyItemStyled>{children}</ModalBodyItemStyled>;

ModalBodyItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ModalBodyItem.defaultProps = {
    children: null,
};

export const ModalSidebarStyled = styled.div`
    flex: 0 1 256px;
    padding: 24px 24px 48px 24px;
    border-right: 1px solid ${({ theme }) => theme.colors.primary3};
`;

export const ModalSidebar = ({ children }) => <ModalSidebarStyled>{children}</ModalSidebarStyled>;

ModalSidebar.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ModalSidebar.defaultProps = {
    children: null,
};

const ModalMainStyled = styled.div`
    flex: 1;
`;

export const ModalMain = ({ children }) => <ModalMainStyled>{children}</ModalMainStyled>;

ModalMain.propTypes = {
    children: PropTypes.node,
};

ModalMain.defaultProps = {
    children: null,
};

const ModalSectionStyled = styled.div`
    width: 100%;
    padding: 32px 48px;

    & + & {
        border-top: 1px solid ${({ theme }) => theme.colors.primary3};
    }
`;

export const ModalSection = ({ children, ...rest }) => <ModalSectionStyled {...rest}>{children}</ModalSectionStyled>;

ModalSection.propTypes = {
    children: PropTypes.node,
};

ModalSection.defaultProps = {
    children: null,
};
